import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.config.productionTip = false
Vue.config.silent = true // 用于发布版本时屏蔽Vue控制台警告问题
Vue.use(ElementUI)
import axios from 'axios'
import moment from "moment";
import Print from 'print-js'
Vue.prototype.$axios = axios;//把axios挂载到vue上
Vue.prototype.$moment = moment;
Vue.prototype.$Print = Print;
Vue.prototype.$EventBus = new Vue();
Vue.config.silent = true // 用于发布版本时屏蔽Vue控制台警告问题·
router.beforeEach((to, from, next) => {
  if (
      to.path === '/singleSignOn'||
      to.path === '/errorPage'
      // || to.path === '/noAccess'
  )  {
    next()
  } else {
    if (sessionStorage.getItem('token')) {
      next()
    } else {
      next({path: '/singleSignOn'})
    }
  }
})

Vue.directive('numberOnly', {
  bind: function (el) {
    el.handler = function () {
      el.childNodes[1].value = el.childNodes[1].value.replace(/\D+/g, '');//因为是elementui组件里使用的el.value获取不到我们输入的内容。然后我发现他的第一个孩子才是我们输入的手机号码的内容。
    }
    el.addEventListener('keyup', el.handler); //这里使用keyup。我开始用的input发现第一次输入拼音字母竟然是可以输入的。改成keyup就都不能输入非数字了
  },
  unbind: function (el) {
    el.removeEventListener('keyup', el.handler);
  }
})
new Vue({
  router,
  render: function (h) { return h(App) }
}).$mount('#app')
